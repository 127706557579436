<template>
    <div>
        <div class="text-2xl p-3 font-medium">Roles Details</div>
        <TabView v-model:activeIndex="activeTab">
            <TabPanel>
                <template #header>
                    <div>Permissions</div>
                </template>
                <div class="reset-grid">
                    <PermissionsListPage is-sub-page ref="permissionsListPage"  field-name="permissions.role_id" :field-value="pageData.role_id" :show-header="false"  v-if="pageReady">
                    </PermissionsListPage>
                </div>
            </TabPanel>
        </TabView>
    </div>
</template>
<script setup>
import {  ref } from 'vue';
import { useApp } from '@/composables/app.js';
import PermissionsListPage from "../permissions/list.vue";
const props = defineProps({
	pageData: null,
	isSubPage: {
		type : Boolean,
		default : true,
	},
});
const app = useApp();
const pageReady = ref(true);
const activeIndex = ref(1);
</script>
